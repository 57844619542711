.tabs {
  @apply py-10  text-sm;
  ul {
    @apply my-[5px] list-disc pl-10 text-sm leading-6 text-black;
  }
  h2 {
    @apply my-4 text-lg font-bold text-[#332e2e];
  }

  h3 {
    @apply my-4 text-base font-bold text-[#332e2e];
  }

  p strong {
    @apply my-[5px] font-bold;
  }
  ul li strong {
    @apply font-bold;
  }
}

.table {
  @apply py-5  text-sm;
  p {
    @apply my-5 mb-4 text-base font-bold;
  }

  table {
    @apply mb-6 w-full text-sm font-light text-black;
  }
  @media (max-width: 768px) {
    table tbody td:before {
      content: attr(data-th) ': ';
      font-weight: 700;
      display: inline-flex;
      align-self: center;
      padding-right: 5px;
    }
  }
  tr {
    @apply flex w-full items-center px-0 py-2.5 text-sm font-normal md:table-row;
  }
  td {
    @apply py-2.5 pl-0 pr-1 text-left text-sm font-light md:w-3/5;
  }
  th {
    @apply py-2.5 pl-1 pr-0 text-left text-sm font-normal md:w-2/5;
  }
  tr:nth-child(odd) {
    @apply bg-[#f7f7f7];
  }
}
